.custom-radio {
  border: 1px solid var(--textPrimaryColor);
  width: 90%;
  height: 50px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

