.ant-select-selector {
  height: 44px !important ;
  padding: 5px 11px !important;
  border-radius: 4px !important;
}
.ant-table-thead,
th {
  background-color: var(--button_bg_primary) !important;
  color: #ffffff !important;
}
.ant-pagination-item-active {
  border-color: var(--button_bg_primary) !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--button_bg_primary) !important;
}
.ant-tabs-tab-btn {
  font-size: 1rem;
  font-weight: 600;
}

.ant-tabs-ink-bar {
  background-color: var(--button_bg_primary) !important;
}
.input-box {
  border-radius: 4px;
  outline: none;
  border: 1px solid #cccccc;
  height: 42px;
  padding-left: 0.5rem;
  width: 100%;
}
.input-box::placeholder {
  opacity: 0.8;
  font-size: 14px;
}

.input-box[type='text']:focus {
  border: 1px solid #40a9ff;
}
.input-style {
  height: 44px;
  border-radius: 4px !important;
}

.add-assessment-button {
  background-color: var(--button_bg_primary);
  color: white;
  font: 14px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  height: 2.5rem;
  width: 12.5rem;
  cursor: pointer;
}

.next-prev-button-container {
  margin: 20px 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.add-questions-button {
  margin: 0 10px;
  border: 1px solid var(--button_bg_primary);
  border-radius: 4px;
  height: 2.5rem;
  padding: 0 30px;
  font-weight: 500;
  color: #ffffff;
  background-color: var(--button_bg_primary);
  cursor: pointer;
}
.import-button-container:hover {
  filter: brightness(90%);
}
.odd-row {
  background-color: #edfff5;
}
.ant-modal-content,
.ant-modal-header {
  border-radius: 10px !important;
}
.upload-file-style {
  border: 2px dashed #cccccc;
  border-radius: 10px;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.browse-files-label {
  color: var(--border_primary);
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: underline;
  cursor: pointer;
}
.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
}

