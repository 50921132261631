.page-not-found-container {
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-not-found-image {
  max-width: 50%;
  height: auto;
}
.oops-text {
  font-weight: 600;
  font-size: 3.75rem;
  margin-bottom: 0;
}
.page-not-found-text {
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.back-to-home-button {
  width: 13.5rem;
  height: 3rem;
  border-radius: 10px;
  background-color: var(--ctaGreen);
  color: var(--mainSecondary);
  border: none;
  font-weight: 600;
  font-size: 1rem;
}
