.card {
  box-shadow: 0 0 1px gray;
}

.card-lg {
  box-shadow: 0 0 2px rgb(128, 128, 128);
}

.divider {
  border-bottom: 1px solid rgb(182, 182, 182);
  margin: 10px 0;
}

.loader-parent {
  position: absolute;
  inset: 0;
  background-color: #000000a3;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  height: 80px;
  width: 80px;
  border-style: solid;
  border-width: 5px;
  border-image: linear-gradient(45deg, #26bf67, #cff9e2, #26bf67) 1;
  border-left-color: transparent;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  border-radius: 50%;
  animation: loader 1s linear infinite;
}

.option {
  box-shadow: rgba(0, 0, 0, 0.638) 0px 0px 1.5px;
  padding: 10px;
  border-radius: 5px;
}

.selected-option {
  box-shadow: rgba(0, 0, 0, 0.638) 0px 0px 1px;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid var(--primary);
}

.result {
  box-shadow: rgba(75, 75, 75, 0.638) 0px 0px 2px;
  padding: 30px;
  color: white !important;
  border-radius: 5px;
}

.lottie-animation {
  height: 300px;
}

.timer {
  color: rgba(0, 0, 0, 0.7) !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 80px;
  width: 80px; */
  border-radius: 5%;
}
.timer-text {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
