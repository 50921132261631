@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf)
      format('truetype');
}

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Header_Text {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 700;
  color: var(--textTernaryColor);
}
.Global_Primary_Button {
  width: 10rem;
  cursor: pointer;
  padding: 0.5rem 0rem;
  background-color: var(--button_bg_primary);
  color: #ffffff;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem;
  border: 1.5px solid #ffffff;
}
.Global_Primary_Button:hover {
  background-color: var(--button_bg_seconday);
}
.Global_Secondary_Button {
  width: 6rem;
  cursor: pointer;
  padding: 0.4rem 0rem;
  color: var(--textTernaryColor);
  font-weight: 700;
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem;
  border: 1.5px solid var(--button_bg_primary);
}
.Global_Secondary_Button:hover {
  background-color: var(--button_bg_seconday);
  color: #ffffff;
}

.Search_Button {
  width: 5rem;
  cursor: pointer;
  padding: 0.3rem 0rem;
  background-color: var(--button_bg_primary);
  color: #ffffff;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem;
  border: 1.5px solid #ffffff;
}
.Search_Button:hover {
  background-color: var(--button_bg_seconday);
}

.Clear_Button {
  width: 3rem;
  cursor: pointer;
  padding: 0.2rem 0rem;
  color: var(--textTernaryColor);
  font-weight: 700;
  transition: 0.3s ease-in-out;
  border-radius: 0.5rem;
  border: 1.5px solid var(--button_bg_primary);
}
.Clear_Button:hover {
  background-color: var(--button_bg_seconday);
  color: #ffffff;
}

