.common-button {
  background-color: var(--button_bg_primary);
  color: var(--mainSecondary);
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 10px 30px;
  cursor: pointer;
}
.common-button:hover {
  filter: brightness(90%);
}
.next-prev-button {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  height: 3rem;
  width: 9rem;
  cursor: pointer;
}
.next-prev-button:hover {
  filter: brightness(90%);
}

.next-button {
  background-color: var(--button_bg_primary);
  color: #ffffff;
  border: none;
}
.prev-button {
  color: var(--button_bg_primary);
  border: 1px solid var(--button_bg_primary);
  background-color: #ffffff;
  margin-right: 3rem;
}
.add-button {
  margin: 0 10px;
  border: 1px solid var(--button_bg_primary);
  border-radius: 4px;
  height: 2.5rem;
  padding: 0 30px;
  font-weight: 500;
  color: #ffffff;
  background-color: var(--button_bg_primary);
  cursor: pointer;
}
.add-button-container :hover {
  filter: brightness(90%);
}
.export-icon-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.export-button-container {
  background-color: var(--button_bg_primary);
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  align-items: center;
  min-width: 45px;
  cursor: pointer;
  padding: 0 5px;
}

.export-button-container:hover {
  filter: brightness(90%);
}

