.input-search-box{
    border-radius: 0.5rem;
    outline: none;
    border: 1.3px solid var(--border_seconday);
    padding-left: 0.5rem;
}
.input-search-box::placeholder{
    opacity: 0.8;
    font-size: 14px;
}

.input-search-box[type=text]:focus {
    border: 2px solid var(--border_primary);
  }
