.Card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  border-radius: 0.5rem;
  width: 15rem;
}
.card-header {
  display: inline;
  text-align: center;
  background-color: var(--primary_background_color_two);
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
}
.card-header h1 {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 3px 0;
  color: #5e5959;
  text-transform: capitalize;
}
.Card-Body-first-Text {
  display: inline;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.Card-Body-Text {
  display: inline;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: var(--textTernaryColor);
}
.card-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.7rem;
  line-height: 0.8rem;
  border-left: 1.5px solid #d1d1d1;
  border-right: 1.5px solid #d1d1d1;
  border-bottom: 1.5px solid #d1d1d1;
  border-radius: 0rem 0rem 0.4rem 0.4rem;
}
.card-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 0.5rem;
}
.card-footer button {
  width: 95%;
  margin: auto;
  border: none;
  cursor: pointer;
  padding: 0.7rem 0rem;
  background-color: var(--button_bg_primary);
  color: #ffffff;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  border-radius: 0.4rem;
}
.card-footer button:hover {
  background-color: var(--button_bg_seconday);;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: #ffffff;
}
.deactive-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 0.2rem;
}
.deactive-button button {
  width: 95%;
  border: none;
  cursor: pointer;
  padding: 0.7rem 0rem;
  background-color: #cccccc;
  color: #ffffff;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  cursor: not-allowed;
  pointer-events: none;
  position: relative;
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;
}
