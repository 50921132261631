main {
  display: grid;
  grid-template-areas: "header header header header header header header header header header header header" "aside aside aside aside  section section section section section section section section ";
  grid-row-gap: 0.5px;
  width: 100%;
}
header {
  height: 4rem;
  grid-area: header;
  position: fixed;
  width: 100%;
  z-index: 9;
}
aside {
  grid-area: aside;
  height: 100vh;
  width: 30%;
  position: fixed;
  padding-top: 5%;
  scroll-behavior: smooth;
  overflow: hidden;
}
section {
  grid-area: section;
  height: 100vh;
  padding-bottom: 3rem;
  margin: auto;
  padding-top: 5rem;
  padding-left: 1%;
  padding-right: 1%;
  margin-left: 12rem;
  /* width: 85%; */
  width: 85%;
  position: fixed;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  /* overflow: hidden; */
}
/* @media only screen and (width> 1200px) {
  section {
    grid-area: section;
    height: 100vh;
    padding-bottom: 3rem;
    margin: auto;
    padding-top: 5rem;
    padding-left: 1%;
    padding-right: 1%;
    margin-left: 1rem;
    width: 100%;
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: lightblue;
  }
} */
.content {
  position: absolute;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding-right: 2rem;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--button_bg_primary);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
