.layout {
  /* padding: 15px; */
  width: 100%;
  height: 100%;
}

.sidebar {
  width: 180px;
  background-color: #052010;
  position: fixed;
  height: 100vh;
  display: flex;
  top: 4.7rem;
  z-index: 100000;
  padding-top: 1rem;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 15px;
  color: #ffffff;
  transition: all 0.3s ease-out;
}

.active-menu-item {
  background-color: var(--button_bg_primary);
  border-radius: 5px;
  height: 2.5rem;
  color: #ffffff;
  font-weight: 700;
  margin-left: 0.5rem;
}
.header-main-text {
  color: #ffffff;
  font-size: 1vw;
}
.header_main {
  color: #ffffff;
  font-weight: 900;
}

.container {
  width: 80%;
  margin-top: 6%;
  padding-bottom: 2%;
  margin-left: 15%;
  height: 100%;
}

.header {
  background-color: #052010;
  color: #313131 !important;
  width: 100% !important;
  padding: 15px;
  align-items: center !important;
  position: fixed;
  z-index: 10000;
}
