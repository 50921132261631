.card-wrapper{
    padding: 2rem 0rem;
}
.empty-card-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.button-wrapper {
    display: flex;
    width: 100%;
    gap: 1rem;
    position: relative;
  }
  .active_button {
    cursor: pointer;
    font-weight: 700;
    font-size: 1.1vw;
    transition: 0.5s ease-in-out;
    border-bottom: 1.5px solid var(--button_bg_primary);
    color: var(--button_bg_primary);
    position: relative;
  }
  .active_button:hover {
    color: var(--button_bg_seconday);
  }
  
  .inactive_button {
    cursor: pointer;
    color: var(--textTernaryColor);
    font-weight: 700;
    font-size: 1.1vw;
    transition: 0.5s ease-in-out;
  }
  .inactive_button:hover {
    color: var(--button_bg_seconday);
  }
  
  /* Card Style  */
  .Card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    border-radius: 0.5rem;
    width: 15rem;
    background-color: #f9f9f9;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary_background_color_two);
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    padding: 0.5rem 0.8rem;
  }
  .card-header-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--warning);
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    padding: 0.5rem 0.8rem;
    color: var(--light);
  }
  .card-header-close h1 {
    font-size: 1.12rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 3px 0;
    color: var(--light);
    text-transform: capitalize;
  }
  .card-header h1 {
    font-size: 1.12rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 3px 0;
    color: #5e5959;
    text-transform: capitalize;
  }
  .Card-Body-first-Text {
    display: inline;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  .Card-Body-Text {
    display: inline;
    font-weight: 500;
    margin: 0;
    padding: 0;
    color: var(--textTernaryColor);
  }
  .card-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 0.7rem;
    line-height: 0.8rem;
    border-left: 1.5px solid #d1d1d1;
    border-right: 1.5px solid #d1d1d1;
    border-bottom: 1.5px solid #d1d1d1;
    border-radius: 0rem 0rem 0.4rem 0.4rem;
  }
  .card-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-bottom: 0.5rem;
  }
  .card-footer button {
    width: 95%;
    margin: auto;
    border: none;
    cursor: pointer;
    padding: 0.7rem 0rem;
    background-color: var(--button_bg_primary);
    color: #ffffff;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    border-radius: 0.4rem;
  }
  .card-footer button:hover {
    background-color: var(--button_bg_seconday);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: #ffffff;
  }
  .deactive-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-bottom: 0.2rem;
  }
  .deactive-button button {
    width: 95%;
    border: none;
    cursor: pointer;
    padding: 0.7rem 0rem;
    background-color: #cccccc;
    color: #ffffff;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    cursor: not-allowed;
    pointer-events: none;
    position: relative;
    border-radius: 0.4rem;
    margin-bottom: 0.5rem;
  }
  .menu_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
    padding: 0.5rem;
    background-color: #ffffff;
    position: absolute;
    right: 0.5rem;
    top: 2rem;
    border-radius: 0.5rem;
    box-shadow: #959da533 0px 8px 24px;
  }
  .menu_button_wrapper {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    gap: 0.5rem;
  }
  .partial_close {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-weight: 600;
    border-radius: 0.2rem;
    font-size: 12px;
    color: var(--textTernaryColor);
    transition: all 0.8s ease-in-out;
  }
  .partial_close:is(:hover) {
    background-color: var(--button_bg_primary);
    padding: 0.2rem 0.5rem;
    color: var(--light);
  }
  .permanent_close {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    color: var(--textTernaryColor);
    border-radius: 0.2rem;
    transition: all 0.8s ease-in-out;
  }
  
  .permanent_close:is(:hover) {
    background-color: var(--button_bg_primary);
    padding: 0.2rem 0.5rem;
    color: var(--light);
  }
  
  .progress {
    margin-bottom: 1rem;
    padding: 0;
    width: 100%;
    height: 10px;
    overflow: hidden;
    background: #e5e5e5;
    border-radius: 6px;
  }
  
  .bar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background-color: var(--button_bg_primary);
  }
  
  .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-family: tahoma, arial, helvetica;
    font-size: 12px;
    color: white;
  }
  
