.result_container{
display: flex;
flex-direction: row;
}
.assessment-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0;
}
.question-text {
  font-size: 1rem;
  font-weight: 400;
}
.options-text {
  font-size: 1rem;
  font-weight: 400;
}

ul li {
  list-style: none;
}
.Footer_Button_Wrapper {
  margin: 3rem 0;
  width: 100%;
  display: flex;
  gap: 1rem;
}

.button-container {
  margin: 40px 0;
  display: flex;
  justify-content: flex-end;
}
.close-button {
  color: #26bf67;
  border: 1px solid #26bf67;
  background-color: #ffffff;
  margin: 0 1rem 0 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  height: 3rem;
  width: 9rem;
  cursor: pointer;
}
.success_background {
  background-color: rgba(51, 171, 132, 0.1);
  border: 1px solid rgba(51, 171, 132, 1);
  border-radius: 4px;
}
.failed_background {
  background-color: rgba(236, 91, 98, 0.1);
  border: 1px solid rgba(236, 91, 98, 1);
  border-radius: 4px;
}
