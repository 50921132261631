.custom-search-container {
  border: 1px solid var(--textPrimaryColor);
  height: 50px;
  border-radius: 6px;
  width: 90%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  /* color: var(--textPrimaryColor); */
}
.custom-search-container:focus {
  outline: 1px solid var(--button_bg_primary);
}
input ::placeholder {
  color: var(--textPrimaryColor);
}
