.email-sent-modal-container{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.done-icon-style{
height: 8rem;
width: 8rem;
}
.email-sent-text{
font-size: 1.5rem;
font-weight: 600;
text-align: center;
}