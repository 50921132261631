:root {
  --primary_background_color: #828181;
  --primary_background_color_two: #cff9e2;
  --secondary_background_color: #2f2e2e;
  --ternary_background_color: #26c0661a;

  /* #26BF67; */

  --mainPrimary: #f4f3ef;
  --mainSecondary: #ffffff;
  --mainternarBgColor: #eb612b;
  --primary: #0f3460;
  --secondary: #ff5722;
  --success: #0f6a12;
  --button_bg_primary: #278357;
  --button_bg_seconday: #0f6a12;
  --border_primary: #26bf67;
  --border_seconday: #65ea9d;
  --info: #00bcd4;
  --warning: #f7a177;
  --danger: #f44336;
  --light: #f1f1f1;
  --dark: #212121;
  --textPrimaryColor: #9a9a9a;
  --textSecondaryColor: #eb612b;
  --textTernaryColor: #2e2d2d8f;
  --ctaGreen: #278357;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-white {
  background-color: #fff !important;
}
.text-white {
  color: white !important;
}

.bg-success {
  background-color: var(--success);
}

.bg-error {
  background-color: var(--warning);
}
