.main-container {
  display: flex;
  width: 100%;
  height: 100vh;
}
.left-container {
  position: relative;
  width: 60%;
  background: url("../../../assets/images/login_screen_logo.png");
  background-color: #cff9e2;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
  -o-background-size: 60% auto;
  -webkit-background-size: 60% auto;
  -moz-background-size: 60% auto;
  background-size: 60% auto;
  height: 100%;
}
.right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}
.form-container h3 {
  font-size: large;
  font-weight: 700;
}
.google-button {
  border: 1.5px solid #cccccc;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  width: 23.25rem;
  border-radius: 10px;
  text-align: center;
}

.sign-in-google-text {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 600;
}
.welcome-text {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
}
.assess-skill-text {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 3.7rem;
}
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 1rem;
  width: 23.25rem;
}
